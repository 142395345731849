@media (max-width: 480px) {
    #root .ant-picker-calendar-header {
        display: flex;
        flex-flow: column;
    }

    #root .ant-picker-calendar-header .ant-picker-calendar-month-select {
        margin-left: 0;
    }

    #root .ant-picker-calendar-header > .ant-select {
        margin-bottom: 5px;
        width: 100%;
    }

    #root .ant-layout-sider {
        height: 100%;
        position: absolute;
        z-index: 4;
    }

}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root, .ant-layout {
    height: 100%;
}

.ant-picker-calendar-header .ant-picker-calendar-mode-switch {
    display: none;
}

.ant-layout-sider-trigger {
    background-color: #000;
}

.login-form-forgot {
    float: right;
}

.login-form-button {
    width: 100%;
    margin-bottom: 5px;
}

.ant-layout-content {
    margin: 24px;
}

.ant-layout-content .ant-picker-calendar {
    padding: 0 24px;
}

.ant-picker-calendar .ant-picker-calendar-header {
    position: sticky;
    padding: 24px 0;
    top: 0;
    z-index: 3;
    background: #fff;
}

.ant-layout-header.header {
    padding: 0 25px;
}

.ant-layout-header.header img {
    margin-bottom: 5px;
}

.ant-menu {
    display: flex;
    flex-flow: column;
    height: 100%;
}

.ant-menu-item-group:nth-child(2) {
    margin-top: auto;
}

#root .ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date {
    padding: 4px 0 0 0;
    margin: 0;
    border: 0;
}

.ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date-value {
    padding: 4px 8px 0;
    margin: 0 4px;
    border-top: 2px solid #f0f0f0;
}

.ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date-content {
    padding: 0;
}

.ant-picker-calendar-date .ant-picker-calendar-date-content div {
    height: 100%;
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.ant-picker-calendar-date .ant-picker-calendar-date-content .reserved {
    color: #1890ff;
    background-color: #e6f7ff;
}

.ant-picker-calendar-date .ant-picker-calendar-date-content .reserved.my {
    color: #fff;
    background-color: #2a52be;
}

.ant-picker-calendar-date .ant-picker-calendar-date-content .reserved.first {
    border-left: 2px solid #1890ff;
}

.ant-picker-calendar-date .ant-picker-calendar-date-content .reserved.last {
    border-right: 2px solid #1890ff;
}

.reserved > div {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

#root .ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected .ant-picker-calendar-date-today {
    background-color: #fff;
}

input[type="color"],
input[type="date"],
input[type="datetime"],
input[type="datetime-local"],
input[type="email"],
input[type="month"],
input[type="number"],
input[type="password"],
input[type="search"],
input[type="tel"],
input[type="text"],
input[type="time"],
input[type="url"],
input[type="week"],
input.ant-input-number-input,
select:focus,
textarea {
    font-size: 16px;
}

#root .ant-descriptions-item-container .ant-descriptions-item-label {
    font-weight: bold;
}

.register-form .ant-row.ant-form-item {
    margin-bottom: 10px;
}

#root .ant-picker-cell-disabled {
    pointer-events: auto;
    cursor: pointer;
}